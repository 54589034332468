
import { Amplify, API, Auth } from "aws-amplify";
import { getEvents, listEvents, getEvent, listTickets, getTickets } from "../graphql/queries";
import { createEvent, deleteEvent, createTickets, deleteTickets, updateTickets } from '../graphql/mutations';
import { getEventInfo, addTicketToEvent, addTicketToLocation} from "./eventsHelpers";
import { getLocationInfo } from "./locationsHelpers";


export async function createNewTicket(SignedIn, loc_id, loc_name, id, user_id, user_name, title, checkout_session,  user_email, why_ticket, end_date) {
    const date = new Date(end_date);
    date.setDate(date.getDate() + 60);
    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();
    day = (day).toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false})
    month = (month).toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false})
    let updatedDate = `${year}-${month}-${day}`;
    const newTickets = await API.graphql({
      query: createTickets,
      variables: {
          input: {
            "eventID": id,
            "eventName": title,
            "status": "active",
            "ownerID": user_id,
            "ownerName": user_name,
            "ownerUserName": user_name,
            "expirationDate": updatedDate,
            "locationsID": loc_id,
            "stripeSessionID": checkout_session,
            "locationName": loc_name,
            "why": why_ticket
          }
        },
        authMode: 'AMAZON_COGNITO_USER_POOLS'
    }); 
    var ticket_id = newTickets["data"]['createTickets']['id'];
    await addTicketToEvent(SignedIn, id);
    await addTicketToLocation(SignedIn, id)
    var return_event = await getEventInfo(SignedIn, id);
    var PDF_key = await createTicketPDF(SignedIn, title, loc_id, user_name, id, updatedDate, return_event.qr)
    const email_sent = await sendEmail(PDF_key, title, ticket_id, user_email);
}

async function createTicketPDF(SignedIn, title, loc_id, user_name, id, updatedDate, tempQR) {
  var qrKey = "public/" + tempQR;
  var return_location = await getLocationInfo(SignedIn, loc_id);
  const date = new Date();
  date.setDate(date.getDate());
  let day = date.getDate();
  let month = date.getMonth();
  let year = date.getFullYear();
  day = (day).toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false})
  month = (month).toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false})
  let todayDate = `${year}-${month}-${day}`;
  var valid_date = todayDate + ' - ' + updatedDate;
  const res_pdf_data = await fetch(process.env.REACT_APP_PDF_GEN_URL, {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      type: 'ticket',
      data: {
        nameOfEvent: title,
        locationName: return_location.address,
        nameOfPerson: user_name,
        ticketId: id,
        valid: valid_date,
        qrKey: qrKey
      }
    })
  });
  const PDF_res = await res_pdf_data.json();
  var PDF_key = PDF_res.success;
  return PDF_key;
}

async function sendEmail(PDF_key, title, id, to_email) {
  var subject = "Your HogNBones " + title + ' Ticket #' + id;
    const res_data = await fetch(process.env.REACT_APP_EMAIL_SEND_URL, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
          "to": to_email,
          "subject": subject,
          "pdfS3Key": PDF_key,
          "eventName":title
      })
    })
    const test = await res_data.json();
    return test;
}

export async function updateStatusToRedeemed(id) {
  const updatedTickets = await API.graphql({
    query: updateTickets,
    variables: {
      input: {
        "id": id,
        "status": "redeemed"
      }
    },
    authMode: 'AMAZON_COGNITO_USER_POOLS'
  });
}

export async function updateStatusToActivePerRequest(id, auth) {
  var temp = "reactived - authorized by " + auth;
  const updatedTickets = await API.graphql({
    query: updateTickets,
    variables: {
      input: {
        "id": id,
        "status": temp
      }
    },
    authMode: 'AMAZON_COGNITO_USER_POOLS'
  });
}

export async function getAllTickets(id) {
    // List all items per event ID
    const variables = { filter: { eventID: { eq: id  } } };
    const allTickets = await API.graphql({
      query: listTickets,
      variables: variables,
      authMode: "AMAZON_COGNITO_USER_POOLS",
    });
    return allTickets['data']['listTickets']['items'];
}

export async function getAllTicketsByLocation(id) {
    // List all items per event ID
    const variables = { filter: { locationsID: { eq: id  } } };
    const allTickets = await API.graphql({
      query: listTickets,
      variables: variables,
      authMode: "AMAZON_COGNITO_USER_POOLS",
    });
    return allTickets['data']['listTickets']['items'];
}


export async function checkIfSessionExists(id) {
  // List all items per event ID
  const variables = { filter: { stripeSessionID: { eq: id  } } };
  const allTickets = await API.graphql({
    query: listTickets,
    variables: variables,
    authMode: 'AMAZON_COGNITO_USER_POOLS'
  });
  return allTickets['data']['listTickets']['items'];
}


export async function getAllTicketsByOwner(id) {
  // List all items per event ID
  const variables = { filter: { ownerID: { eq: id  }, status: { eq: 'active'  },  } };
  const allTickets = await API.graphql({
    query: listTickets,
    variables: variables,
    authMode: "AMAZON_COGNITO_USER_POOLS",
  });
  return allTickets['data']['listTickets']['items'];
}

export async function deleteTicket(id) {
    const deleteTicket = await API.graphql({
        query: deleteTickets,
        variables: {
            input: {
                id: id
            }
        },
        authMode: 'AMAZON_COGNITO_USER_POOLS'
    });
  }